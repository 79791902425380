// Ref: https://github.com/thetrevorharmon/sell-things-fast/blob/master/src/context/StoreContext.js

import React, { useState, useEffect, useContext } from "react"

// Use the unoptimized version of the shopify-buy SDK to enable expanding
//    graphQLClient queries to include additional fields (i.e., quantityAvailable)
// ref: https://github.com/Shopify/js-buy-sdk/issues/753
// ref: https://github.com/Shopify/js-buy-sdk#expanding-the-sdk
// import Client from "shopify-buy"
import Client from "shopify-buy/index.unoptimized.umd"

const SHOPIFY_CHECKOUT_STORAGE_KEY = "shopify_checkout_id"

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_PUBLIC_STOREFRONT_API_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_STOREURL,
})

const initialStoreState = {
  client,
  isAdding: false,
  checkout: { lineItems: [] },
}

const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null,
})

function createNewCheckout(store) {
  return store.checkout.create()
}

function fetchCheckout(store, id) {
  return store.client.checkout.fetch(id)
}

function setCheckoutInState(checkout, setStore) {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id)
  }

  setStore(prevState => {
    return { ...prevState, checkout }
  })
}

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(initialStoreState)

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== "undefined"
      const existingCheckoutId = isBrowser
        ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
        : null

      if (existingCheckoutId) {
        try {
          const checkout = await fetchCheckout(client, existingCheckoutId)
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout, setStore)
            return
          }
        } catch (e) {
          localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null)
        }
      }

      const newCheckout = await createNewCheckout(client)
      setCheckoutInState(newCheckout, setStore)
    }

    initializeCheckout()
  }, [])

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

function useStore() {
  const { store } = useContext(StoreContext)
  return store
}

function useCartCount() {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const count = checkout.lineItems.reduce(
    (runningTotal, item) => item.quantity + runningTotal,
    0
  )

  return count
}

function useCartTotals() {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const tax = checkout.totalTaxV2 ? checkout.totalTaxV2.amount : "-"
  const total = checkout.totalPriceV2 ? checkout.totalPriceV2.amount : "-"

  return {
    tax,
    total,
  }
}

function useCartItems() {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  return checkout.lineItems
}

function useAddItemToCart() {
  const {
    store: { checkout, client },
    setStore,
  } = useContext(StoreContext)

  async function addItemToCart(variantId, quantity) {
    if (variantId === "" || !quantity) {
      console.error("Both a variantId and quantity are required.")
      return
    }

    setStore(prevState => {
      return { ...prevState, isAdding: true }
    })

    const checkoutId = checkout.id
    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]

    const newCheckout = await client.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    )

    setStore(prevState => {
      return { ...prevState, checkout: newCheckout, isAdding: false }
    })
  }

  return addItemToCart
}

function useRemoveItemFromCart() {
  const {
    store: { client, checkout },
    setStore,
  } = useContext(StoreContext)

  async function removeItemFromCart(itemId) {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      itemId,
    ])

    setStore(prevState => {
      return { ...prevState, checkout: newCheckout }
    })
  }

  return removeItemFromCart
}

function useCheckout() {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  return () => {
    // Replace the default checkout url with the store subdomain url.
    // window.open(checkout.webUrl)

    const checkoutUrl = checkout.webUrl.replace(
      "6deb59.myshopify.com",
      "shop.njtautomation.com"
    )
    window.open(checkoutUrl)
  }
}

async function getProductAvailability(id) {
  const product = await client.product.fetch(id)
  if (product) {
    const variant = product.variants[0]
    if (variant) {
      return variant.available
    }
  }
  return false
}

async function getInventoryQuantity(productId, productTitle) {
  // To Do
  // The query requires either a first or last arg. Here we are using "first: 10".
  // I believe this is for paging, but I'm not quite sure how to work with it.
  // I tried to add an arg to query our specific product id, but it returns no records
  //      "query:${productId}"
  // I added a title query, which does return the expected result.
  // Research different queries I could use (like "product" instead of "products").
  const query = client.graphQLClient.query(root => {
    root.addConnection(
      "products",
      { args: { first: 10, query: `title:${productTitle}` } },
      product => {
        product.add("title")
        product.addConnection("variants", { args: { first: 1 } }, variant => {
          variant.add("quantityAvailable")
        })
      }
    )
  })

  const response = await client.graphQLClient.send(query)
  const product = response.model.products.find(p => p.id === productId)
  if (product) {
    return product.variants[0].quantityAvailable
  }

  return 0
}

async function getInventoryQuantityIndexBySearchQuery(searchQuery) {
  const query = client.graphQLClient.query(root => {
    root.addConnection(
      "products",
      { args: { first: 250, query: `title:*${searchQuery}*` } },
      product => {
        product.add("id")
        product.add("title")
        product.addConnection("variants", { args: { first: 1 } }, variant => {
          variant.add("quantityAvailable")
        })
      }
    )
  })

  const response = await client.graphQLClient.send(query)
  let result = []
  response.model.products.forEach(product => {
    result.push({
      shopifyId: product.id,
      quantityAvailable: product.variants[0].quantityAvailable,
    })
  })

  return result
}

export {
  StoreContextProvider,
  getProductAvailability,
  getInventoryQuantity,
  getInventoryQuantityIndexBySearchQuery,
  useAddItemToCart,
  useStore,
  useCartCount,
  useCartItems,
  useCartTotals,
  useRemoveItemFromCart,
  useCheckout,
}
